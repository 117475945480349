import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Menu, MenuItem } from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef, GridColumnHeaders, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { copyParcel, createParcel, deleteParcel, generateInviteSubjectAndBody, loadAttendees, loadParcels } from 'api';
import { createPlaybookParcel, deletePlaybookParcel, getPlaybookParcels } from 'api/playbooks';
import TitleInputModal from 'components/Communication/TitleInputModal';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import HowToPopper from 'components/HowToPopper/HowToPopper';
import { Spinner } from 'components/Spinner';
import { Button } from 'components/Ui-V2/Button/Button';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { StyledGridToolbarQuickFilter } from 'components/Ui-V2/SearchInput/SearchInput';
import { PageHeadline } from 'components/ui/Headline';
import EmptyCommunicationsImage from 'images/empty-pages-assets/empty-estimates.png';
import { useSnackbar } from 'notistack';
import { PageDescription } from 'pages/BuildInquiry';
import { ParcelFormState, TEventParcelContext } from 'pages/EditParcel/EditParcel';
import {
    HOTEL_CONF,
    INVITE,
    NOTE,
    PRIVATE_INVITE,
    REGISTRATION_PAGE,
    SURVEY,
    copyRegistrationSlugToClipboard,
    isParcelType,
    parcelTypes,
} from 'pages/EditParcel/utils';
import { PageNotFound } from 'pages/PageNotFound';
import { createPlaybookActions } from 'pages/PlaybookWizard/store';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { Column, Row, SpacedRow, Spacer } from 'ui';
import { parcelType } from '../../util';

const Main = styled.div`
    width: 100%;
`;

const SearchContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    padding: 15px 10px;
    align-items: center;
    width: 100%;
`;

const TitleCell = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StatusCell = styled.div<{ status: string }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-radius: 4px;
    color: ${getColor(EColors.pureBlack)};
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    padding: 0 20px;

    ${props =>
        props.status === 'Sent' &&
        `
        background-color: ${getColor(EColors.green, 0.6)}
    `}

    ${props =>
        props.status === 'Scheduled' &&
        `
        background-color: ${getColor(EColors.preferredRank30, 0.6)}
    `}

    ${props =>
        props.status === 'Draft' &&
        `
        background-color: ${getColor(EColors.grey, 0.6)}
    `}

    ${props =>
        props.status === 'Launched' &&
        `
        background-color: ${getColor(EColors.primaryAction, 0.6)}
    `}
`;

const GridContainer = styled(Box)`
    height: 570px;
    width: auto;
`;

const StyledDataGridPro = styled(DataGridPro)({
    borderRadius: '4px',
    width: '100%',
    marginTop: '-5px',
    borderBottom: `1px solid ${getColor(EColors.lighterGrey)}`,
    rowHeight: 35,

    '& .MuiDataGrid-container--top [role=row], & .MuiDataGrid-container--bottom [role=row]': {
        backgroundColor: getColor(EColors.lighterGrey),
    },

    '& .MuiDataGrid-cell': {
        paddingRight: '20px',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    },

    '& .MuiDataGrid-row:hover': {
        backgroundColor: getColor(EColors.lighterGrey, 0.3),
        cursor: 'pointer',
        border: 'none',
    },

    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#f5f5f5',
        border: 'none',
    },

    '& .MuiDataGrid-filler': {
        display: 'none',
    },
});

const VisualContainer = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60vh;
`;

type TColumns = 'title' | 'sendDate' | 'communicationType' | 'status' | 'actions';

type CommunicationProps = {
    playbookId?: number | string;
    readonly?: boolean;
    asWizardModule?: boolean;
    showCreateButton?: boolean;
    hiddenColumns?: TColumns[];
    setIsParcelCreating: (isCreating: boolean) => void;
};

export default function Communication({
    playbookId,
    readonly = false,
    showCreateButton = true,
    hiddenColumns = [],
    setIsParcelCreating,
}: CommunicationProps) {
    const { user } = useUser();
    const { event } = useEvent();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { type: parcelTypeFromUrl } = useParams();

    const [deleteIntent, setDeleteIntent] = useState<Bizly.Parcel | null>(null);
    const [parcels, setParcels] = useState<Bizly.Parcel[]>([]);

    const [loading, setLoading] = useState(true);
    const [searchTerm] = useState('');
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const [activeRowId, setActiveRowId] = useState<string | null>(null);
    const [isTitleModalOpen, setTitleModalOpen] = useState(false);
    const [selectedType, setSelectedType] = useState<Bizly.ParcelType | undefined>(undefined);

    const webRegistrationRedesignFlagEnabled = useFeatureFlagEnabled('AiWebRegistrationRedesign');

    const handleMenuOpen = (event: React.MouseEvent<SVGElement>, rowId: string) => {
        const target = event.currentTarget as unknown as HTMLElement;
        if (activeRowId === rowId) {
            setMenuAnchor(null);
            setActiveRowId(null);
        } else {
            setMenuAnchor(target);
            setActiveRowId(rowId);
        }
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
        setActiveRowId(null);
    };

    const removeParcel = playbookId ? deletePlaybookParcel : deleteParcel;
    const id = playbookId ?? event.id;

    const editLinkDestination = (parcel: Bizly.Parcel) => {
        if (playbookId) {
            return `/playbooks/${playbookId}/edit/communication/edit/${parcelType(parcel)}/${parcel.id}`;
        }

        return `/event/${event.id}/communication/edit/${parcelType(parcel)}/${parcel.id}`;
    };

    const load = useCallback(async () => {
        try {
            if (playbookId) {
                const parcelsResponse = await getPlaybookParcels(playbookId);
                setParcels(parcelsResponse.parcels as Bizly.Parcel[]);
            } else {
                const parcelsResponse = await (loadParcels as (id: number | string) => Promise<Bizly.Parcel[]>)(id);
                setParcels(parcelsResponse as Bizly.Parcel[]);
            }
        } catch (e) {
            enqueueSnackbar(
                playbookId ? i18n.communication.error.fetchPlaybook(id) : i18n.communication.error.fetchMeeting(id)
            );
        } finally {
            setLoading(false);
        }
    }, [enqueueSnackbar, id, playbookId]);

    useEffect(() => {
        load();
    }, [load]);

    const formatDate = (dateString: string | null | undefined): string => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        return dateString ? new Date(dateString).toLocaleDateString('en-US', options) : '';
    };

    const handleCopyClick = async (parcel: Bizly.Parcel) => {
        if (!parcel.id) return;
        await copyParcel(parcel.id);
        load();
    };

    const handleDeleteClick = (parcel: Bizly.Parcel) => {
        setDeleteIntent(parcel);
    };

    const handleDeleteConfirm = async () => {
        if (!deleteIntent) return;
        await removeParcel(id, deleteIntent);
        load();
        setDeleteIntent(null);
    };

    const handleDeleteDismiss = () => {
        setDeleteIntent(null);
    };

    const filteredRows = (parcels || [])
        .filter(parcel => {
            if (parcelTypeFromUrl && parcelTypes.includes(parcelTypeFromUrl as Bizly.ParcelType)) {
                return (
                    parcel?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    (parcel.type === parcelTypeFromUrl ||
                        (parcel.type === PRIVATE_INVITE && parcelTypeFromUrl === INVITE) ||
                        (parcel.type === SURVEY && parcelTypeFromUrl === NOTE))
                );
            }

            return parcel?.name?.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .map(parcel => {
            const currentParcelType = parcel ? parcelType(parcel as Bizly.Parcel) : null;
            const isPublished = !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.published;
            const isSubmitted =
                ((currentParcelType === INVITE ||
                    currentParcelType === NOTE ||
                    currentParcelType === SURVEY ||
                    currentParcelType === HOTEL_CONF) &&
                    parcel.sent) ||
                (currentParcelType === REGISTRATION_PAGE && isPublished) ||
                false;

            const sendDate = isSubmitted
                ? currentParcelType === REGISTRATION_PAGE
                    ? Array.isArray(parcel.traits)
                        ? ''
                        : formatDate(parcel.traits?.public?.registrationPage?.publishedAt)
                    : formatDate(parcel.sentAt)
                : parcel.sendAt
                  ? formatDate(parcel.sendAt)
                  : '';
            const communicationTypeMap: Record<string, string> = {
                registration_page: i18n.communication.registration_page,
                invite: i18n.communication.invite,
                survey: i18n.communication.survey,
                note: i18n.communication.noteSurvey,
                rsvp: i18n.communication.privateInvite,
                hotel_conf: i18n.communication.hotel_conf,
            };
            const communicationType =
                communicationTypeMap[parcel.type as keyof typeof communicationTypeMap] || parcel.type;
            const status = isSubmitted
                ? currentParcelType === REGISTRATION_PAGE
                    ? i18n.communication.launched
                    : i18n.communication.sent
                : parcel.sendAt
                  ? i18n.communication.scheduled
                  : i18n.communication.draft;

            return {
                ...parcel,
                id: parcel.id,
                title: currentParcelType === HOTEL_CONF ? parcel.subject : parcel.name,
                sendDate,
                communicationType,
                status,
            };
        })
        .sort((a, b) => (b.id ?? -Infinity) - (a.id ?? -Infinity));

    const columns: GridColDef<Bizly.Parcel>[] = [
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            renderCell: (params: GridCellParams) => <TitleCell>{params.value as string}</TitleCell>,
        },
        { field: 'sendDate', headerName: 'Sent Date', width: 175 },
        { field: 'communicationType', headerName: 'Type', width: 175 },
        {
            field: 'status',
            headerName: 'Status',
            width: 175,
            renderCell: (params: GridCellParams) => (
                <StatusCell status={params.value as string}>{params.value as string}</StatusCell>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 175,
            renderCell: (params: GridCellParams) => {
                const type = params.row ? parcelType(params.row as Bizly.Parcel) : null;
                const isParcelPublished =
                    !Array.isArray(params.row.traits) && params.row.traits?.public?.registrationPage?.published;
                const rowId = params.row.id;

                return (
                    <>
                        <MoreHorizIcon
                            style={{ cursor: 'pointer', marginTop: '10px' }}
                            onClick={(e: React.MouseEvent<SVGElement>) => handleMenuOpen(e, rowId)}
                        />

                        <Menu
                            anchorEl={menuAnchor}
                            open={menuAnchor !== null && activeRowId === rowId}
                            onClose={handleMenuClose}
                        >
                            <Link to={editLinkDestination(params.row)}>
                                <MenuItem onClick={handleMenuClose}>
                                    {params.row?.sent || isParcelPublished ? i18n.button.view : i18n.button.edit}
                                </MenuItem>
                            </Link>
                            {type === REGISTRATION_PAGE && isParcelPublished && (
                                <MenuItem
                                    onClick={async () => {
                                        handleMenuClose();
                                        await copyRegistrationSlugToClipboard({ parcel: params.row, eventId: id });
                                        enqueueSnackbar(i18n.communication.copiedURL, { variant: 'success' });
                                    }}
                                >
                                    {i18n.button.copyUrl}
                                </MenuItem>
                            )}
                            {(type === INVITE || type === NOTE || type === SURVEY) && (
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        handleCopyClick(params.row);
                                    }}
                                >
                                    {i18n.button.copy}
                                </MenuItem>
                            )}
                            {!params.row?.sent && !isParcelPublished && (
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        handleDeleteClick(params.row);
                                    }}
                                >
                                    {i18n.button.delete}
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                );
            },
        },
    ];

    const initialStateColumns = {
        columns: {
            columnVisibilityModel: {
                sendDate: !hiddenColumns.includes('sendDate'),
                actions: !hiddenColumns.includes('actions'),
            },
        },
    };

    const virtualMeetingServiceProvider = useMemo(
        () =>
            user.team?.virtualMeetingServiceProviders?.find(
                serviceProvider => serviceProvider.id === event?.virtualMeeting?.serviceProvider?.id
            ),
        [user.team, event?.virtualMeeting]
    );

    const getParcel = async (type: Bizly.ParcelType, title: string, instruction?: string) => {
        let attendees: Bizly.EventAttendee[] = [],
            recipients: number[] = [];
        if (!playbookId) {
            const response = await loadAttendees(event.id);
            attendees = response?.attendees || [];
            recipients = attendees.map(({ id }: { id: number }) => id);
        }

        const parcel: ParcelFormState = initialParcelState(type, recipients, event, virtualMeetingServiceProvider);

        parcel.name = title;

        if (type !== 'registration_page') return parcel;

        parcel.subject = event.name;

        const result = await generateInviteSubjectAndBody(
            event.name || 'Event',
            instruction || event.description || '',
            event.location || '',
            event.startsAt || '',
            event.endsAt || '',
            user.firstName || '',
            user.team?.name || ''
        );

        if (result.success) {
            parcel.content = result.result.body;
        }

        return parcel;
    };

    const handleCreate = async (type: Bizly.ParcelType, title: string, instruction?: string) => {
        try {
            setIsParcelCreating(true);

            const parcel = await getParcel(type, title, instruction);

            const newParcelRes = playbookId
                ? await createPlaybookParcel(playbookId, parcel)
                : await createParcel(event.id, parcel);

            const newParcelId = newParcelRes.parcel.id;

            if (playbookId) {
                createPlaybookActions.triggerParcelsLoad();
            }

            if (!webRegistrationRedesignFlagEnabled) {
                enqueueSnackbar(`${i18n.communication.draftCreated}.`, { variant: 'success' });
            }

            const redirectUrl = playbookId
                ? `/playbooks/${playbookId}/edit/communication/edit/${type}/${newParcelId}`
                : `/event/${event.id}/communication/edit/${type}/${newParcelId}`;

            navigate(redirectUrl);
        } catch (e) {
            enqueueSnackbar(i18n.error.default, { variant: 'error' });
            const redirectUrl = playbookId ? `/playbooks/${playbookId}/edit` : `/event/${event.id}/communication`;
            navigate(redirectUrl);
        } finally {
            setTimeout(() => setIsParcelCreating(false), 1000);
        }
    };

    const handleTypeSelect = (type: Bizly.ParcelType | undefined) => {
        const defaultTitle = 'Default Title';
        if (type === 'hotel_conf') {
            handleCreate(type, defaultTitle);
            return;
        }
        setSelectedType(type);
        setTitleModalOpen(true);
    };

    const handleContinue = (title: string, instruction?: string) => {
        if (selectedType) {
            handleCreate(selectedType, title, instruction);
            setSelectedType(undefined);
            setTitleModalOpen(false);
        }
    };

    const handleTitleModalClose = () => {
        setTitleModalOpen(false);
        setSelectedType(undefined);
    };

    if (!playbookId && !isParcelType(parcelTypeFromUrl)) {
        return <PageNotFound />;
    }

    if (loading) {
        return <Spinner />;
    }

    const parcelTypeLabel = (type: string) => {
        switch (type) {
            case INVITE:
            case PRIVATE_INVITE:
                return 'Invites';
            case REGISTRATION_PAGE:
                return 'Registrations';
            case HOTEL_CONF:
                return 'Hotel Confirmations';
            case NOTE:
                return 'Survey / Notes';
            default:
                return '';
        }
    };

    return (
        <>
            {parcels && parcels.length ? (
                <Main>
                    <DeleteConfirmationModal
                        onDismiss={handleDeleteDismiss}
                        onProceed={handleDeleteConfirm}
                        prompt={i18n.communication.deletePrompt(
                            playbookId ? i18n.communication.playbook : i18n.communication.meeting
                        )}
                        isActive={!!deleteIntent}
                    />

                    {!playbookId && !readonly ? (
                        <SpacedRow>
                            <Column>
                                <Row alignItems="center">
                                    <PageHeadline withDescription>
                                        {i18n.communication.invitesCommunication}
                                    </PageHeadline>
                                    <HowToPopper sectionId="communications" />
                                </Row>
                                <PageDescription large>{i18n.communication.description}</PageDescription>
                            </Column>

                            <Button
                                fullWidth={false}
                                onClick={() => handleTypeSelect(parcelTypeFromUrl as Bizly.ParcelType)}
                                // endIcon={
                                //     isCreating ? (
                                //         <CircularProgress size="1rem" sx={{ color: getColor(EColors.pureWhite) }} />
                                //     ) : null
                                // }
                            >
                                {i18n.communication.create}
                            </Button>
                        </SpacedRow>
                    ) : (
                        showCreateButton && (
                            <Row justifyContent="flex-end">
                                <Button
                                    fullWidth={false}
                                    onClick={() => handleTypeSelect(parcelTypeFromUrl as Bizly.ParcelType)}
                                >
                                    {i18n.communication.create}
                                </Button>
                            </Row>
                        )
                    )}

                    <Spacer larger />

                    <GridContainer>
                        <StyledDataGridPro
                            rows={filteredRows}
                            columns={columns}
                            sortingOrder={['desc', 'asc']}
                            sortModel={[{ field: 'id', sort: 'desc' }]}
                            initialState={initialStateColumns}
                            disableColumnResize
                            hideFooter
                            disableColumnMenu
                            autoHeight={Boolean(filteredRows.length)}
                            slots={{
                                toolbar:
                                    filteredRows.length === 0
                                        ? () => null
                                        : () => (
                                              <GridToolbarContainer>
                                                  <SearchContainer>
                                                      <StyledGridToolbarQuickFilter placeholder="Search Title" />
                                                  </SearchContainer>
                                              </GridToolbarContainer>
                                          ),
                                noRowsOverlay: () => (
                                    <EmptyVisual
                                        image={<img src={EmptyCommunicationsImage} alt="No items" />}
                                        title={i18n.communication.noCommunicationsCreatedYet(
                                            parcelTypeLabel(parcelTypeFromUrl as Bizly.ParcelType)
                                        )}
                                        description={i18n.communication.invitesDescription}
                                    />
                                ),
                                columnHeaders: filteredRows.length === 0 ? () => null : GridColumnHeaders,
                            }}
                        />
                    </GridContainer>
                </Main>
            ) : (
                <VisualContainer>
                    <EmptyVisual
                        image={<img src={EmptyCommunicationsImage} alt="Empty Communications" />}
                        title={i18n.communication.noCommunicationsCreatedYet(
                            parcelTypeLabel(parcelTypeFromUrl as Bizly.ParcelType)
                        )}
                        description={i18n.communication.invitesDescription}
                        actionButton={
                            <Button
                                sx={{ width: '125px' }}
                                onClick={() => handleTypeSelect(parcelTypeFromUrl as Bizly.ParcelType)}
                                // endIcon={
                                //     isCreating ? (
                                //         <CircularProgress size="1rem" sx={{ color: getColor(EColors.pureWhite) }} />
                                //     ) : null
                                // }
                            >
                                {i18n.communication.create}
                            </Button>
                        }
                    />
                </VisualContainer>
            )}

            {isTitleModalOpen && selectedType && (
                <TitleInputModal
                    open={isTitleModalOpen}
                    type={selectedType}
                    onClose={handleTitleModalClose}
                    onSubmit={handleContinue}
                />
            )}
        </>
    );
}

const generateParcelTrait = (type: Bizly.ParcelType) => {
    if (type === INVITE) {
        return { rsvp: [] };
    }

    if (type === REGISTRATION_PAGE) {
        return ['rsvp', 'public'];
    }

    if (type === HOTEL_CONF) {
        return ['hotel_conf'];
    }

    return [];
};

const initialParcelState = (
    type: Bizly.ParcelType,
    recipients: number[],
    event: TEventParcelContext,
    vmServiceProvider?: Bizly.VirtualMeetingServiceProvider
) => {
    return {
        name: '',
        recipients,
        subject: '',
        content: '',
        locationName: event.bookedVenue?.name,
        locationAddress: event.bookedVenue?.address,
        locationCityState: event.bookedVenue?.cityState,
        traits: generateParcelTrait(type),
        ...(vmServiceProvider
            ? {
                  vmSharingMethod: 'share' as 'share' | 'dont_share',
              }
            : {}),
    };
};
